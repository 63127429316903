import React, { useState } from "react";
import Login from "../../components/Login";
import background from "../../assets/img/backgroundutama.png"; //Background
import backdrop from "../../assets/img/background.png";
import kamad from "../../assets/img/Kamad.png";
import backgroundMobile from "../../assets/img/backgroundmobile.png";
import logo from "../../assets/img/logo.png"; //Logo

function Index(props) {
  const appName = "Login";
  const [showLogin, setShowLogin] = useState(false);
  const deskripsi = "Selamat datang di";
  const deskripsi2 = "Belum punya akun? Silakan hubungi Admin Madrasah";
  const title2 = "SEMAR MATSAYO";
  const title = "MATSAYO";
  const slogan1 = "Sistem Manajemen Ajar MTs Negeri 1 Yogyakarta";
  const copyright = "2024 Semar MTsN 1 Yogyakarta. - Didukung oleh";

  return (
    <>
      <style>
        {`
          @media (max-width: 768px) {
            .gambar .backdrop,
            .gambar .logo {
              display: none !important;
            }
            .gambar .title {
              display: none !important;
            }  
            .gambar .kamad {
              position: absolute;
              top: 50px;
              left: 45%;
              transform: translateX(-50%);
              width: 100px;
              height: auto;
            }
          }
        `}
      </style>
      <div className="col-12 hero-container">
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.7) 100%, rgba(255, 255, 255, 0) 100%), url(${background})`,
          }}
          className="hero-background col-12"
        ></div>
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 100%, rgba(255, 255, 255, 0) 100%), url(${backgroundMobile})`,
          }}
          className="hero-background hero-bg-mobile col-12"
        ></div>
        <div
          className="col-12 hero"
          style={{
            backgroundPosition: "0 100%",
            backgroundRepeat: "no-repeat",
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 81.78%, rgba(255, 255, 255, 0) 95.16%), url(${props.overlay})`,
          }}
        >
          <div className="">
            <div className="text-light" data-page={appName} style={{}}>
              <div
                className="menu"
                style={{ textAlign: "right", textColor: "white" }}
              ></div>
              <div className="title-mobile">
                <img
                  src={logo}
                  alt={appName}
                  style={{ width: 80, height: 77, marginTop: 50 }}
                />
                <br></br>
                <br></br>
                <h1
                  style={{
                    fontSize: "40px",
                    marginTop: 50,
                    fontFamily: "Poppins",
                    textAlign: "center",
                  }}
                >
                  {title2}
                </h1>
                <span
                  style={{
                    fontSize: "18px",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontStyle: "italic",
                  }}
                >
                  {slogan1}
                </span>
                <br></br>
                <div
                  className="login"
                  style={{
                    display: "inline-table",
                    width: "50px",
                    padding: "5px 50px 5px 50px",
                    marginTop: "20px",
                    marginBottom: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                    background: "#43B27F",
                    borderRadius: 8,
                  }}
                  onClick={() => setShowLogin(true)}
                >
                  <span
                    style={{
                      fontSize: "150%",
                      textAlign: "left",
                      fontFamily: "Poppins",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    Login
                  </span>
                </div>
              </div>
              <div
                className="container"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div className="deskripsi" style={{ flex: 1 }}>
                  <div className="slogan">
                    <p
                      style={{
                        fontSize: "200%",
                        marginTop: 30,
                        fontFamily: "Poppins",
                        marginLeft: 50,
                      }}
                    >
                      {deskripsi}
                    </p>
                    <br></br>
                    <p
                      style={{
                        fontSize: "350%",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        marginLeft: 50,
                      }}
                    >
                      {title2}
                    </p>
                    <p
                      style={{
                        fontSize: "200%",
                        fontFamily: "Poppins",
                        fontStyle: "italic",
                        marginLeft: 50,
                      }}
                    >
                      {slogan1}
                    </p>
                    <Login />
                    <p
                      style={{
                        fontSize: "120%",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        marginLeft: 100,
                      }}
                    >
                      {deskripsi2}
                    </p>
                  </div>
                </div>
                <div
                  className="gambar"
                  style={{ flex: 1, position: "relative", textAlign: "center" }}
                >
                  <img
                    className="backdrop"
                    src={backdrop}
                    alt="Gambar Deskripsi"
                    style={{
                      width: "100%",
                      height: "auto",
                      marginTop: "50px",
                    }}
                  />
                  <img
                    className="logo"
                    src={logo}
                    alt="Logo"
                    style={{
                      position: "absolute",
                      top: "55px",
                      left: "20px",
                      width: "100px",
                      height: "100px",
                    }}
                  />
                  <div
                    className="title"
                    style={{
                      position: "absolute",
                      top: "30px",
                      left: "100px",
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "50px",
                        fontStyle: "italic",
                        fontFamily: "Zona Pro",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      MATSAYO
                    </p>
                    <p
                      style={{
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                      }}
                    >
                      MTs Negeri 1 Yogyakarta
                    </p>
                  </div>
                  <img
                    className="kamad"
                    src={kamad}
                    alt="Foto Kepala Sekolah"
                    style={{
                      position: "absolute",
                      bottom: "1px",
                      right: "150px",
                      width: "300px",
                      height: "400px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {showLogin && (
            <div
              onClick={(e) => setShowLogin(false)}
              style={{
                position: "fixed",
                backdropFilter: "blur(4px)",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ maxWidth: "600px", width: "100%" }}
                onClick={(e) => e.stopPropagation()}
              >
                <Login />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
